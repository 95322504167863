import React, { useState } from 'react'
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { holidayApi } from '../api/holidayApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';

const isHoliday = (stat) => {
    if (stat == true) return <CheckCircleIcon fontSize='small' color='success' />
    return <CancelIcon fontSize='small' color='error' />
}

const columns = [
    {
        field: 'holiday',
        headerName: 'Holiday/Disconnect Day',
        headerClassName: 'whiteMenuIcon',
        paddingLeft: '10px',
        width: 320,
        // align: 'center',
        renderCell: (params) =>
            <Typography fontWeight={600} fontFamily='Segoe UI' fontSize={14} pl={2} color={(params.row.chennai && params.row.gurgaon && params.row.hyderabad && params.row.kolkata && params.row.mumbai && params.row.bengaluru) ? '#197444' : 'initial'}>{params.row.holiday}</Typography>
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 200,
        headerClassName: 'centerStyle',
        renderCell: (params) => <Typography fontSize={14} fontFamily='Segoe UI' pl={2}>{params.row.date}</Typography>
    },
    {
        field: 'bengaluru',
        headerName: 'Bengaluru',
        headerClassName: 'centerStyle',
        width: 100,
        align: 'center',
        renderCell: (params) => isHoliday(params.row.bengaluru)
    },
    {
        field: 'chennai',
        headerName: 'Chennai',
        width: 100,
        headerClassName: 'centerStyle',
        align: 'center',
        renderCell: (params) => isHoliday(params.row.chennai)
    },
    {
        field: 'gurgaon',
        headerName: 'Gurgaon',
        width: 100,
        headerClassName: 'centerStyle',
        align: 'center',
        renderCell: (params) => isHoliday(params.row.gurgaon)
    },
    {
        field: 'hyderabad',
        headerName: 'Hyderabad',
        width: 110,
        headerClassName: 'centerStyle',
        align: 'center',
        renderCell: (params) => isHoliday(params.row.hyderabad)
    },
    {
        field: 'kolkata',
        headerName: 'Kolkata',
        width: 100,
        headerClassName: 'centerStyle',
        align: 'center',
        renderCell: (params) => isHoliday(params.row.kolkata)
    },
    {
        field: 'mumbai',
        headerName: 'Mumbai & Pune',
        width: 150,
        headerClassName: 'centerStyle',
        align: 'center',
        renderCell: (params) => isHoliday(params.row.mumbai)
    },
];

const columnsUSA = [
    {
        field: 'holiday',
        headerName: 'Holiday/Disconnect Day',
        headerClassName: 'whiteMenuIcon',
        paddingLeft: '10px',
        width: 320,
        renderCell: (params) =>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Box pl={2}>{params.row.holiday}</Box>
                {
                    params.row.holiday === "New Year's Day/Eve"
                        ? <Tooltip title="If you work a night shift and your shift falls on 
                        New Year's Eve rather than New Year's Day, you will receive your TOP 
                        hours for the New Year's Day holiday on New Year's Eve">
                            <InfoIcon sx={{ fontSize: '12px', color: '#4769c3' }} />
                        </Tooltip>
                        : <></>
                }
            </Stack>
    },
    {
        field: 'date',
        headerName: 'Date',
        width: 200,
        headerClassName: 'centerStyle',
        align: 'center',
        // renderCell: (params) => <strong>{params.row.date}</strong>
    },
];

const rows = [
    { id: 1, holiday: "New Year's Day", date: 'Wednesday, January 1', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 2, holiday: "Makara Sankranti / Pongal", date: 'Tuesday, January 14', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: false, mumbai: true },
    { id: 3, holiday: "Netaji Subhash Chandra Bose Jayanti", date: 'Thursday, January 23', bengaluru: false, chennai: false, gurgaon: false, hyderabad: false, kolkata: true, mumbai: false },
    { id: 4, holiday: "Holi / Doljatra", date: 'Friday, March 14', bengaluru: true, chennai: false, gurgaon: true, hyderabad: false, kolkata: true, mumbai: true },
    { id: 5, holiday: "Eid Ul Fitr", date: 'Monday, March 31', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 6, holiday: "Tamil New Year / Ambedkar Jayanti Day", date: 'Monday, April 14', bengaluru: false, chennai: true, gurgaon: true, hyderabad: false, kolkata: false, mumbai: false },
    { id: 7, holiday: "May/Labor Day", date: 'Thursday, May 1', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 8, holiday: "Telangana Formation Day", date: 'Monday, June 2', bengaluru: false, chennai: false, gurgaon: false, hyderabad: true, kolkata: false, mumbai: false },
    { id: 9, holiday: "Independence Day", date: 'Friday, August 15', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 10, holiday: "Ganesh Chaturthi", date: 'Wednesday, August 27', bengaluru: true, chennai: true, gurgaon: false, hyderabad: true, kolkata: false, mumbai: true },
    { id: 11, holiday: "Maha Navami", date: 'Wednesday, October 1', bengaluru: false, chennai: false, gurgaon: false, hyderabad: false, kolkata: true, mumbai: false },
    { id: 12, holiday: "Gandhi Jayanti / Dussehra(Vijayadashami)", date: 'Thursday, October 2', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 13, holiday: "Extended Diwali", date: 'Monday, October 20', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 14, holiday: "Diwali", date: 'Tuesday, October 21', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
    { id: 15, holiday: "Christmas Day", date: 'Thursday, December 25', bengaluru: true, chennai: true, gurgaon: true, hyderabad: true, kolkata: true, mumbai: true },
];

const rowsUSA = [
    { id: 1, holiday: "New Year's Day/Eve", date: 'Wednesday, January 1' },
    { id: 2, holiday: "Martin Luther King, Jr. Day", date: 'Monday, January 20' },
    { id: 4, holiday: "Memorial Day", date: 'Monday, May 26' },
    { id: 5, holiday: "Juneteenth", date: 'Thursday, June 19' },
    { id: 6, holiday: "Independence Day ", date: 'Friday, July 4' },
    { id: 7, holiday: "Labor Day", date: 'Monday, September 1' },
    { id: 8, holiday: "Veterans Day", date: 'Tuesday, November 11' },
    { id: 9, holiday: "Thanksgiving Day", date: 'Thursday, November 27' },
    { id: 10, holiday: "Extended Thanksgiving", date: 'Friday, November 28' },
    { id: 11, holiday: "Christmas Eve", date: 'Wednesday, December 24' },
    { id: 12, holiday: "Christmas Day", date: 'Thursday, December 25' },
];


export const HolidaysBox = () => {
    const [open, setOpen] = useState(false);
    const [openUSA, setOpenUSA] = useState(false);

    const showHoliday = () => {
        setOpen(true);
    }

    const showUSAHoliday = () => {
        setOpenUSA(true);
    }

    const onClose = () => {
        setOpen(false);
        setOpenUSA(false);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ background: '#F5FAFF', borderRadius: '5px' }}>
                <Box px={2} py={1} borderBottom={'solid 1px #E0E0E0'}>
                    <Typography fontWeight="600" color="#1B2C58">Calendar</Typography>
                </Box>

                <Stack justifyContent='center' alignItems='center'>
                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                        <DemoItem>
                            <DateCalendar
                                sx={{
                                    "& .MuiDayCalendar-weekContainer": {
                                        // margin: '8px 0'
                                    }
                                }}
                                defaultValue={dayjs(new Date())}
                                readOnly />
                        </DemoItem>
                    </DemoContainer>

                    <Stack direction='row' columnGap={2} mt={1}>
                        <Button onClick={showHoliday} sx={{ marginBottom: '12px' }} variant='contained'>Holidays | IN</Button>
                        <Button onClick={showUSAHoliday} sx={{ marginBottom: '12px' }} variant='contained'>Holidays | USA</Button>
                    </Stack>
                </Stack>
            </Box>

            <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth='lg' >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableColumnSelector
                    hideFooter
                    disableColumnMenu
                    disableColumnSorting
                    style={{ backgroundColor: 'white' }}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: 'primary.main'
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontSize: '16px',
                            fontFamily: 'Segoe UI'
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            display: 'none'
                        },
                        '& .MuiInputBase-input': {
                            height: '20px !important'
                        },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                            boxShadow: 'none',
                            border: 'none'
                        },
                    }}
                />
                <DialogActions><Button variant='contained' size='sm' color='error' onClick={onClose}>Cancel</Button></DialogActions>
            </Dialog>


            <Dialog onClose={onClose} open={openUSA}>
                <DataGrid
                    rows={rowsUSA}
                    columns={columnsUSA}
                    disableColumnSelector
                    hideFooter
                    disableColumnMenu
                    disableColumnSorting
                    style={{ backgroundColor: 'white' }}
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: 'primary.main',
                        },
                        '& .MuiDataGrid-cell': {
                            fontSize: '15px',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            // fontWeight: 'bold',
                            fontSize: '16px',
                            fontFamily: 'Segoe UI'
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            display: 'none'
                        },
                        '& .MuiInputBase-input': {
                            height: '20px !important'
                        },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                            boxShadow: 'none',
                            border: 'none'
                        },
                    }}
                />
                <DialogActions><Button variant='contained' color='error' size='sm' onClick={onClose}>Cancel</Button></DialogActions>
            </Dialog>
        </LocalizationProvider>
    )
}
