import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import Notify from "../../../features/helpers/notifyConfig";
import { NodataFound } from "../../NodataFound";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Skeleton, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UserIcon from '@mui/material/Avatar';
import { Cancel } from "@mui/icons-material";
import { PieChart } from '@mui/x-charts/PieChart';
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from "@mui/lab";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";

const AnalyticsView = () => {
    const [infodata, setInfodata] = useState(null);
    const [manData, setData] = useState(null);
    const [value, setValue] = useState('date');
    const [date, setDate] = useState(null);
    const [datePicked, setDatePicked] = useState(null);
    const [loading, setLoading] = useState(false);
    const [global, setGlobal] = useState(null);

    const calendarValues = {
        date: ['month', 'year'],
        year: ['year'],
        month: ['month'],
        from: ['year', 'month', 'day'],
        to: ['year', 'month', 'day'],
    }

    const manageRequests = (data) => {
        setData(data);
    }

    const onClose = () => {
        setData(null);
    }

    //1. get all expenses of this year
    const getAllExpenses = async (prop = "") => {
        let query = "";
        if (prop !== "" && date !== null) {
            query = `?filter=${date}`
        }
        setLoading(true);
        await axios.get(`/expense-report/summary/${query}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }
        ).then((response) => {
            let finData = response?.data;
            setInfodata(finData);

            if (global === null) {
                const filteredData = [...finData].sort(
                    (a, b) => (b.currency === "₹" ? b.totalExpense : (b.totalExpense * 84.98)) - (a.currency === "₹" ? a.totalExpense : (a.totalExpense * 84.98)))
                    ?.map((val) => ({
                        employeeName: val.employeeName,
                        totalExpense: val.totalExpense,
                        pic_icon: val.pic_icon,
                        currency: val.currency,
                    })).slice(0, 10)
                setGlobal(filteredData);
            }
        }).catch((error) => {
            Notify.error(error.response ? (error?.response?.data?.error) : "Request Timed Out");
        }).finally(() =>
            setLoading(false)
        );
    }

    useEffect(() => {
        getAllExpenses();
    }, [])

    const selectDate = (e) => {
        setDatePicked(e);

        const month = e.$M + 1;
        const day = e.$D + 1;
        const year = e.$y;

        if (value === "date") setDate(`year:${year},month:${month}`);
        else if (value === "month") setDate(`month:${month}`);
        else if (value === "year") setDate(`year:${year}`);
        else if (value === "from") setDate(`from:${year}-${month}-${day}`);
        else if (value === "to") setDate(`to:${year}-${month}-${day}`);
    }

    const handleChange = (value) => {
        // setInfodata(null);
        setDatePicked(null);
        setValue(value);
    };

    const getDataByDate = () => {
        setDate(null);
        getAllExpenses("sd");
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {

            return (
                <Paper>
                    <Box className="custom-tooltip" paddingX={2.5} paddingY={2}>
                        <b className="label" style={{color: "#198f51"}}>{payload[0].payload.employeeName}</b>
                        <Typography className="intro" sx={{mt: "10px"}}><span style={{fontWeight: "600"}}>Claimed Expense: </span>{payload[0].payload.currency} {payload[0].payload.totalExpense}</Typography>
                    </Box>
                </Paper>
            );
        }

        return null;
    };

    return (
        <>
            <Stack direction='row' justifyContent='start' mb={3} spacing={2} alignItems='center'>
                <Box sx={{ minWidth: 140 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Filter Options</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            label="Filter Options"
                            onChange={(e) => handleChange(e.target.value)}
                        >
                            <MenuItem value="date"><Typography variant='subtitle2'>Date</Typography></MenuItem>
                            {/* <MenuItem value="month"><Typography variant='subtitle2'>Month</Typography></MenuItem> */}
                            <MenuItem value="year"><Typography variant='subtitle2'>Year</Typography></MenuItem>
                            <MenuItem value="from"><Typography variant='subtitle2'>Start From</Typography></MenuItem>
                            <MenuItem value="to"><Typography variant='subtitle2'>End To</Typography></MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            sx={{
                                '& .MuiOutlinedInput-input': {
                                    paddingY: "10.5px"
                                }
                            }}
                            value={datePicked}
                            views={calendarValues[value]}
                            onChange={selectDate}
                        />
                    </LocalizationProvider>
                </Box>

                <LoadingButton
                    loading={loading}
                    disabled={date === null}
                    variant="contained"
                    onClick={getDataByDate}
                >Submit</LoadingButton>
                <LoadingButton
                    // loading={loading}
                    disabled={loading}
                    variant="outlined"
                    onClick={() => getAllExpenses()}
                >Clear</LoadingButton>
            </Stack>

            <Grid container spacing={2}>
                {
                    (loading)
                        ? <Stack gap={2} width="100%" p={2}>
                            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
                        </Stack>
                        : (infodata !== null && infodata?.length !== 0
                            ? [...infodata].sort(
                                (a, b) => (b.currency === "₹" ? b.totalExpense : (b.totalExpense * 84.98)) - (a.currency === "₹" ? a.totalExpense : (a.totalExpense * 84.98)))
                                ?.map((val, key) => {
                                    return (
                                        <Grid item xl={3} lg={4} md={6} sm={12}>
                                            <Stack
                                                onClick={() => manageRequests(val)}
                                                direction='row'
                                                justifyContent='space-between'
                                                flexWrap="wrap"
                                                alignItems='center'
                                                sx={{
                                                    cursor: 'pointer',
                                                    transition: 'ease-in-out 0.3s',
                                                    borderLeft: '6px solid white',
                                                    ':hover': {
                                                        boxShadow: 4,
                                                        borderLeft: '6px solid #1b2c58',
                                                    },
                                                }} boxShadow={2} bgcolor='white' borderRadius='20px' p={2}>

                                                {/* profile pic */}
                                                <Box>
                                                    <Stack direction="row" spacing={1.5} alignItems='center'>
                                                        {(val.pic_icon == "" || val.pic_icon == null) ? <Box><UserIcon sx={{ width: '3rem', height: '3rem', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', borderRadius: '50%' }} /></Box>
                                                            : <Box
                                                                sx={{
                                                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                                                                    width: '3.2rem',
                                                                    height: '3.2rem',
                                                                    backgroundImage: `url(${val.pic_icon})`,
                                                                    backgroundSize: 'cover',
                                                                    backgroundPosition: 'center',
                                                                    borderRadius: '50%',
                                                                }}></Box>}
                                                        <Box
                                                        >
                                                            <Typography color='primary' fontWeight='600' variant='subtitle1' fontFamily='Segoe UI'>{val.employeeName}</Typography>
                                                            <Typography color='gray' fontWeight='400' fontSize='9px' fontFamily='Segoe UI'>Employee</Typography>
                                                        </Box>
                                                    </Stack>
                                                </Box>

                                                <Box>
                                                    <Stack direction="row" alignItems='center'>
                                                        <Typography color='primary.main' fontFamily='Segoe UI' fontSize='14px' variant='subtitle2' fontWeight='400'>Expenses:</Typography>
                                                        <Typography color='#198f51' textAlign='center' ml={1} fontFamily='Segoe UI' fontSize='14px' fontWeight='600'>{val.currency + " " + val.totalExpense}</Typography>
                                                    </Stack>
                                                </Box>

                                            </Stack>
                                        </Grid>
                                    )
                                })
                            : <Grid item sm={12}><NodataFound msg="No claims here" /></Grid>)
                }
            </Grid>

            {
                global !== null &&
                <Box>
                    <Divider
                        sx={{
                            width: '100%',
                            borderColor: "#706f6f2e",
                            marginY: "1rem",
                            marginLeft: "auto"
                        }}
                    />

                    <Stack alignItems="center">
                        <h2>Top 10 Claim holders</h2>
                        <BarChart
                            width={1000}
                            height={500}
                            data={global}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="4 4" />
                            <XAxis dataKey="employeeName" angle={-20} textAnchor="end" interval={0} height={100} />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend verticalAlign="top" height={50} />
                            <Bar name="Total Claimed Expense" dataKey="totalExpense" fill="#1B2C58" barSize={30} radius={[5, 5, 0, 0]} />
                        </BarChart>
                    </Stack>
                </Box>
            }

            {/* 1. Dialog box to show details of the respective request */}
            <Dialog fullWidth={true} maxWidth='md' open={manData ? true : false} onClose={onClose}>
                {manData
                    ? <>
                        <DialogTitle borderBottom='solid 1px lightgray'>
                            <Stack>
                                <Typography variant="body" fontFamily='Segoe UI'>Expense Info of {manData.employeeName}</Typography>
                                <Typography variant="caption">Piechart representing Categorywise expense distribution and Table showing Projects related to the expenses</Typography>
                            </Stack>
                        </DialogTitle>

                        <DialogContent>
                            <Grid container justifyContent='center' alignItems='center' mt={2}>
                                <Grid item md={8} sm={12}>
                                    {/* <Typography variant="body" fontFamily='Segoe UI' textAlign='center'>Category-wise Expenses</Typography> */}
                                    <PieChart
                                        series={[
                                            {
                                                arcLabel: (item) => `${item.currency}${item.value}`,
                                                arcLabelMinAngle: 35,
                                                // arcLabelRadius: '60%',
                                                innerRadius: 20,
                                                data: [
                                                    ...manData.categories.map((vaal, key) => {
                                                        return {
                                                            id: key,
                                                            value: vaal.totalExpense,
                                                            label: vaal.category,
                                                            currency: manData.currency,
                                                        }
                                                    })
                                                ],
                                                cornerRadius: 5,
                                            },
                                        ]}
                                        width={400}
                                        height={200}
                                    />
                                </Grid>

                                <Grid item md={4} sm={12}>
                                    <DataGrid
                                        rows={
                                            manData.projects.map((vaal, key) => ({
                                                id: key,
                                                project: vaal
                                            }))
                                        }
                                        columns={[
                                            { field: 'project', headerName: 'Projects', flex: 1, headerClassName: 'whiteMenuIcon' }
                                        ]}
                                        // pageSize={pageSize}
                                        disableColumnSelector
                                        hideFooter
                                        // showCellVerticalBorder
                                        disableSelectionOnClick
                                        rowHeight={50}
                                        style={{ backgroundColor: 'white' }}
                                        sx={{
                                            '& .MuiDataGrid-virtualScroller': {
                                                maxHeight: "20rem"
                                            },
                                            '& .MuiDataGrid-columnHeaders': {
                                                backgroundColor: '#657EBF'
                                            },
                                            '& .MuiDataGrid-cell': {
                                                fontSize: '15px',
                                                backgroundColor: "#cfd5f2"
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                                fontWeight: 'bold',
                                                fontSize: '16px'
                                            },
                                            '& .MuiDataGrid-columnSeparator': {
                                                display: 'none'
                                            },
                                            '& .MuiInputBase-input': {
                                                height: '20px !important'
                                            },
                                            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': {
                                                outline: 'none',
                                                boxShadow: 'none',
                                                border: 'none'
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions sx={{ pb: '15px', pr: '20px' }}>
                            <Button sx={{ position: "absolute", top: 15, right: 10 }} onClick={onClose} color="error">
                                <Cancel sx={{ fontSize: "1.8rem" }} />
                            </Button>
                        </DialogActions>
                    </>
                    : <></>}
            </Dialog>
        </>
    )
}

export default AnalyticsView