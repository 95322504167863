import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Box, Tabs, Tab, Stack, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ManageExpense } from './ManageExpense';
import { isSuperAdmin } from '../../../components/auth/userAuth';
import AnalyticsView from './AnalyticsView';

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontFamily: 'Segoe UI',
    fontSize: 16
}));

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ManageTabView() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }} py={1} px={3}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <AntTab label="Approval Status" {...a11yProps(0)} />
                    {
                        isSuperAdmin(< AntTab label="Analytics" {...a11yProps(1)} />, <></>)
                    }
                </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
                <ManageExpense />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
                <AnalyticsView />
            </CustomTabPanel>

        </Box>
    );
}