import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Box, Tabs, Tab, Stack, Skeleton, FormControl, InputLabel, Select, MenuItem, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '@mui/material';
import Notify from '../../../features/helpers/notifyConfig';
import axios from '../../../api/axios';
import { styled } from '@mui/material/styles';
import { capitalizeFirstLetter, ExpenseRequests } from './ExpenseRequests';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { ClaimDate } from './ClaimDate';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export function ManageExpense() {
  const [value, setValue] = useState("pending");
  const [infodata, setInfodata] = useState(null);
  const [reqdata, setReqdata] = useState(null);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(null);

  //1. get projects and manager info regarding expense
  const getExpInfo = async () => {
    await axios.get("/expense-report",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }
    ).then((response) => {
      // console.log(response);
      setInfodata(response?.data?.expenseReportDependancies);
    }).catch((error) => {
      Notify.error(error.response ? (error?.response?.data?.error) : "Request Timed Out");
    });
  }

  //2. get projects and manager info regarding expense
  const getreqInfo = async () => {
    await axios.get("/expense-report/pending",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ` + localStorage.getItem('access')
        }
      }
    ).then((response) => {
      setReqdata(response?.data?.expenseReportPendingClaims);
    }).catch((error) => {
      Notify.error(error.response ? (error?.response?.data?.error) : "Request Timed Out");
    });
  }

  const roundButtonStyle = {
    borderRadius: "2rem !important",
    paddingX: "1.8rem"
  }

  useEffect(() => {
    getExpInfo();
    getreqInfo();
  }, [reload])

  const handleChange = (value) => {
    setValue(value);
  };

  const onClose = () => {
    setOpen(false);
  }

  const selectDate = (e) => {
    const month = e.$M + 1;
    const year = e.$y;

    setDate(`year:${year},month:${month}`);
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>

        {/* <Stack alignItems='center' justifyContent='space-between' direction='row' >
          <Typography ml={1} variant='h5' fontFamily='Segoe UI'>{capitalizeFirstLetter(value)} Claims</Typography>

          <Stack alignItems='center' justifyContent='space-between' direction='row' gap={2}>
            <Tooltip title="View Claim history">
              <CalendarMonthIcon
                onClick={() => setOpen(true)}
                sx={{
                  cursor: "pointer",
                  color: "#1b2c58"
                }}
              />
            </Tooltip>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label="Status"
                onChange={(e) => handleChange(e.target.value)}
              >
                <MenuItem value="pending"><Typography variant='subtitle2'>Pending</Typography></MenuItem>
                <MenuItem value="approved"><Typography variant='subtitle2'>Approved</Typography></MenuItem>
                <MenuItem value="rejected"><Typography variant='subtitle2'>Rejected</Typography></MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack> */}
        <Stack direction="row" gap={2} mb={3}>
          <Button
            variant={value === "pending" ? "contained" : "outlined"}
            sx={roundButtonStyle}
            onClick={() => handleChange("pending")}
          >Pending Claims</Button>
          <Button
            variant={value === "approved" ? "contained" : "outlined"}
            sx={roundButtonStyle}
            onClick={() => handleChange("approved")}
          >Approved Claims</Button>
          <Button
            variant={value === "rejected" ? "contained" : "outlined"} ś
            sx={roundButtonStyle}
            onClick={() => handleChange("rejected")}
          >Rejected Claims</Button>
        </Stack>

        {/* <div className='divider_line'></div> */}

        {(infodata && reqdata)
          ? <ExpenseRequests setReload={setReload} infodata={infodata} reqdata={reqdata.filter((val) => val.status == value)} value={value} />
          : <Stack gap={2} width="100%">
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={80} />
          </Stack>
        }
      </Box>


      {/* Show Dialog box for claim history */}
      <Dialog onClose={onClose} open={open} fullWidth={true} maxWidth='lg'  >
        <DialogTitle>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker views={['month', 'year']} onChange={selectDate} />
          </LocalizationProvider>
        </DialogTitle>
        <DialogContent>
          <Divider sx={{ mb: "1rem" }} />
          {date
            ? <ClaimDate date={date} />
            : <Typography variant='h6'>Please select the date above to see the respective claims</Typography>
          }
        </DialogContent>
        <DialogActions><Button variant='contained' color='error' size='sm' onClick={onClose}>Cancel</Button></DialogActions>
      </Dialog>
    </>
  );
}